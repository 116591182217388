package common.animation.loadingspinner

import common.animation.Transition
import kotlin.math.roundToInt

class LoadingSpinnerAnimation {

    // ----------------------------------------------------------------------------
    // Public properties
    // ----------------------------------------------------------------------------

    val mTransitions = mutableListOf<Transition<LoadingSpinnerState>>()


    // ----------------------------------------------------------------------------
    // Protected properties
    // ----------------------------------------------------------------------------

    // ----------------------------------------------------------------------------
    // Private properties
    // ----------------------------------------------------------------------------

    private val mDefaultTransitionDuration = 1000L

    // ----------------------------------------------------------------------------
    // Internal properties
    // ----------------------------------------------------------------------------

    // ----------------------------------------------------------------------------
    // Init
    // ----------------------------------------------------------------------------
    init {
        mTransitions.apply {
            add(Transition(0, 0, LoadingSpinnerState()))
            add(Transition(0, mDefaultTransitionDuration, last().mData.copy(mIsRollerColorPainted = true)))
            add(Transition(500, mDefaultTransitionDuration, last().mData.copy(mIsRollerColorPainted = false), 0))
        }
    }

    // ----------------------------------------------------------------------------
    // Constructor
    // ----------------------------------------------------------------------------

    // ----------------------------------------------------------------------------
    // System/Overridden methods
    // ----------------------------------------------------------------------------

    // ----------------------------------------------------------------------------
    // Public methods
    // ----------------------------------------------------------------------------

    // ----------------------------------------------------------------------------
    // Protected methods
    // ----------------------------------------------------------------------------

    // ----------------------------------------------------------------------------
    // Private methods
    // ----------------------------------------------------------------------------

    // ----------------------------------------------------------------------------
    // Interfaces/Listener
    // ----------------------------------------------------------------------------

}