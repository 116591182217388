package core.io.dto

import core.enums.EPostMessageActions
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ColourCollectionDTO(

    @SerialName("action")
    val mAction: EPostMessageActions,

    @SerialName("data")
    val mData: List<ColourDTO>? = null,

    @SerialName("isColourApplied")
    val mIsColourApplied: Boolean? = null,

    @SerialName("base64")
    val mBase64: String? = null

)