
import core.ionic.setupConfig
import core.piwik.Piwik
import csstype.px
import kotlinext.js.require
import kotlinx.browser.document
import kotlinx.dom.appendElement
import react.createElement
import react.dom.render
import screens.Root

fun main() {

    /* Core CSS required for Ionic components to work properly */
    require("@ionic/react/css/core.css")


    /* Basic CSS for apps built with Ionic */
    require("@ionic/react/css/normalize.css")
    require("@ionic/react/css/structure.css")
    require("@ionic/react/css/typography.css")

    /* Optional CSS utils that can be commented out */
    require("@ionic/react/css/padding.css")
    require("@ionic/react/css/float-elements.css")
    require("@ionic/react/css/text-alignment.css")
    require("@ionic/react/css/text-transformation.css")
    require("@ionic/react/css/flex-utils.css")
    require("@ionic/react/css/display.css")
    require("swiper/swiper-bundle.css")
    require("@ionic/react/css/ionic-swiper.css")
    setupConfig()

    Piwik.init()

    document.head?.let {
        it.appendElement("style") {
            innerHTML = """
                ion-button {
                    --box-shadow: none;
                }
            """.trimIndent()
        }
    }

    document.body?.let {
        it.style.margin = 0.px.toString()
        document.getElementById("root")?.let { root ->
            render(createElement(Root), root)
        }
    }
}