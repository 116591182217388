package screens.cookieExplanation

import common.EConstants
import components.Toolbar
import components.burgerMenu.BurgerMenu
import core.ionic.EmptyProps
import core.ionic.IonContent
import core.ionic.IonHeader
import core.ionic.IonPage
import kotlinx.browser.document
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLIFrameElement
import react.FC
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.script
import react.dom.html.ScriptHTMLAttributes
import react.useEffect
import react.useRef

val CookieExplanationScreen = FC<EmptyProps> {

    val mDivRef = useRef<HTMLDivElement>(null)
    useEffect(mDivRef) {
        val script = document.createElement("script").asDynamic()
        script.src = "https://consent.cookiebot.com/71dbed9d-aa8a-4843-b145-1c53c4dea8ea/cd.js"
        script.async = true
        mDivRef.current?.appendChild(script)

        cleanup {
            mDivRef.current?.innerHTML = ""
        }
    }

    BurgerMenu()
    IonPage {
        id = EConstants.BURGER_MENU_CONTENT.mId
    }
    IonHeader {
        Toolbar() {
            mIsBackNavigation = true
        }
    }
    IonContent {
        div {
            id = "cookieDeclarationCustomId"
            ref = mDivRef
        }
    }
}