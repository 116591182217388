@file:Suppress("MemberVariableNaming", "TopLevelPropertyNaming", "MatchingDeclarationName")

package components.alert
import core.ionic.IonAlert
import react.FC
import react.Props
import react.key

external interface IAlertProps : Props {
    var mAlertItem: AlertItem
}

val Alert = FC<IAlertProps> { props ->

    val mAlertItem = props.mAlertItem

    IonAlert {
        key = mAlertItem.mKey
        isOpen = mAlertItem.mIsOpen
        message = mAlertItem.mMessage
        buttons = mAlertItem.mButtons
        onDidDismiss = {
            mAlertItem.mIsOpen = false
        }
    }
}