package common.animation

import common.ETourColourItemState

class TourAnimations {

    // ----------------------------------------------------------------------------
    // Public properties
    // ----------------------------------------------------------------------------

    val mTransitions = mutableListOf(
        Transition(0, 150, TourAnimationState())
    )

    // ----------------------------------------------------------------------------
    // Protected properties
    // ----------------------------------------------------------------------------

    // ----------------------------------------------------------------------------
    // Private properties
    // ----------------------------------------------------------------------------

    // ----------------------------------------------------------------------------
    // Internal properties
    // ----------------------------------------------------------------------------

    // ----------------------------------------------------------------------------
    // Init
    // ----------------------------------------------------------------------------
    init {
        mTransitions.apply {
            add(Transition(0, 200, last().mData))
            add(Transition(1500, 100, last().mData.copy(mFlashVisibility = true)))
            add(Transition(500, 100, last().mData.copy(mFlashVisibility = false)))
            add(Transition(200, 1500, last().mData.copy(mScale = TourAnimationConstants.mHandWithPhoneScale), mTransitions.lastIndex.plus(1)))

            add(Transition(0, 800, last().mData))
            add(Transition(400, 1000, last().mData.copy(mColourItemState = ETourColourItemState.SMALL_DOT)))
            add(Transition(1000, 800, last().mData.copy(mColourItemState = ETourColourItemState.SMALL_SQUARE)))
            add(Transition(10, 400, last().mData.copy(mIsMyColoursBackgroundVisible = true), mTransitions.lastIndex.plus(1)))

            add(Transition(0, 800, last().mData.copy(mIsMyColoursBackgroundVisible = false)))
            add(Transition(400, 800, last().mData))
            add(Transition(1000, 800, last().mData, mTransitions.lastIndex.plus(1)))

            add(Transition(0, 800, last().mData.copy(mIsWholeBackgroundBlurred = true)))
            add(Transition(800, 800, last().mData))
            add(Transition(100, 800, last().mData.copy(mColourItemState = ETourColourItemState.LARGE_SQUARE)))
            add(Transition(1200, 800, last().mData.copy(mIsColourItemBuyOptionScaled = true)))
            add(Transition(800, 800, last().mData.copy(mIsColourItemBuyOptionScaled = false), mTransitions.lastIndex.plus(1)))
        }
    }

    // ----------------------------------------------------------------------------
    // Constructor
    // ----------------------------------------------------------------------------

    // ----------------------------------------------------------------------------
    // System/Overridden methods
    // ----------------------------------------------------------------------------

    // ----------------------------------------------------------------------------
    // Public methods
    // ----------------------------------------------------------------------------

    // ----------------------------------------------------------------------------
    // Protected methods
    // ----------------------------------------------------------------------------

    // ----------------------------------------------------------------------------
    // Private methods
    // ----------------------------------------------------------------------------

    // ----------------------------------------------------------------------------
    // Interfaces/Listener
    // ----------------------------------------------------------------------------

}