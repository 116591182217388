package screens

import components.loadingSpinner.GlobalLoadingSpinner
import core.AppContext
import core.MyAppContext
import core.enums.EPostMessageActions
import core.io.dto.ColourCollectionDTO
import core.io.dto.MinifiedColourDTO
import core.ionic.IonApp
import core.ionic.IonContent
import core.localStorage.LocalStorageDAO
import core.util.exhaustive
import kotlinext.js.asJsObject
import kotlinx.browser.window
import kotlinx.serialization.json.Json
import org.w3c.dom.MessageEvent
import org.w3c.dom.events.Event
import react.*
import react.router.Route
import react.router.Routes
import react.router.dom.BrowserRouter
import screens.colourFinder.ColourFinderModuleScreen
import screens.colourFinder.ColourFinderUploadScreen
import screens.cookieExplanation.CookieExplanationScreen
import screens.detail.ColourProductDetailScreen
import screens.detail.ColourToneDetailScreen
import screens.list.*
import screens.static.*
import screens.wallColourer.ShowSelectedColoursOnWallScreen
import screens.wallColourer.WallColourerUploadScreen

external interface RootProps : Props {
}

val Root = FC<RootProps> { rootProps ->

    var mIsLoading by useState(false)

    val mSerializer = Json {
        ignoreUnknownKeys = true
        isLenient = true
    }

    val mLocalStorageReducer = useReducer(
        LocalStorageDAO::reducer, LocalStorageDAO.LocalStorageState(
            LocalStorageDAO.mWatchlist,
            LocalStorageDAO.mProductList,
            LocalStorageDAO.mFixedList,
            LocalStorageDAO.mSuggestedList,
            LocalStorageDAO.mDetailColourObject,
            LocalStorageDAO.mDetailColourProductObject,
            LocalStorageDAO.mSettingsObject
        )
    )

    useEffectOnce {
        val eventMethod: (Event) -> Unit = { event ->
            event as MessageEvent
            val target = event.data?.asJsObject().unsafeCast<String>()
            val dto = kotlin.runCatching { mSerializer.decodeFromString<ColourCollectionDTO>(target) }.getOrNull()

            when (dto?.mAction) {
                EPostMessageActions.SET_FIXED_LIST -> {
                    mLocalStorageReducer.component2().invoke(LocalStorageDAO.ELocalStorageAction.CLEAR_FIXED_LIST)
                    dto.mData?.forEach {
                        mLocalStorageReducer.component2().invoke(
                            LocalStorageDAO.ELocalStorageAction.ADD_OR_DELETE_FROM_FIXEDLIST(
                                MinifiedColourDTO(
                                    it.mUnique,
                                    it.mName,
                                    it.mArtNr,
                                    it.mColourCode,
                                    it.mColourCollection,
                                    it.mAdditionalInfo1,
                                    it.mAdditionalInfo2,
                                    it.mEanCode
                                )
                            )
                        )
                    }
                }

                null -> {}
                else -> {}
            }.exhaustive
        }

        window.addEventListener(type = "message", eventMethod)
        cleanup {
            window.removeEventListener(type = "message", eventMethod)
        }
    }

    AppContext.Provider(MyAppContext(mIsLoading, { mIsLoading = it }, mLocalStorageReducer)) {
        IonApp {
            IonContent {
                BrowserRouter {

                    Routes {
                        Route {
                            path = EScreens.HOME_SCREEN.mPath
                            element = createElement(HomeScreen)
                        }
                        Route {
                            path = EScreens.COLOUR_FINDER_UPLOAD_SCREEN.mPath
                            element = createElement(ColourFinderUploadScreen)
                        }
                        Route {
                            path = EScreens.COLOUR_FINDER_MODULE_SCREEN(null).getRoute()
                            element = createElement(ColourFinderModuleScreen)
                        }
                        Route {
                            path = EScreens.COLOUR_TONE_LIST_SCREEN.mPath
                            element = createElement(ColourToneListScreen)
                        }
                        Route {
                            path = EScreens.WATCH_LIST_SCREEN.mPath
                            element = createElement(WatchListScreen)
                        }
                        Route {
                            path = EScreens.COLOUR_TONE_DETAIL.mPath
                            element = createElement(ColourToneDetailScreen)
                        }
                        Route {
                            path = EScreens.COLOUR_PRODUCT_DETAIL_SCREEN.mPath
                            element = createElement(ColourProductDetailScreen)
                        }
                        Route {
                            path = EScreens.PRODUCT_LIST_SCREEN.mPath
                            element = createElement(ShowProductListScreen)
                        }
                        Route {
                            path = EScreens.WALL_COLOURER_UPLOAD_SCREEN.mPath
                            element = createElement(WallColourerUploadScreen)
                        }
                        Route {
                            path = EScreens.WALL_COLOURER_MODULE_SCREEN(null, null).getRoute()
                            element = createElement(ShowSelectedColoursOnWallScreen)
                        }
                        Route {
                            path = EScreens.IMPRINT_SCREEN.mPath
                            element = createElement(ShowImprintScreen)
                        }
                        Route {
                            path = EScreens.INTEGRITY_SCREEN.mPath
                            element = createElement(ShowIntegrityScreen)
                        }
                        Route {
                            path = EScreens.PRIVACY_SCREEN.mPath
                            element = createElement(ShowPrivacyScreen)
                        }
                        Route {
                            path = EScreens.USAGE_SCREEN.mPath
                            element = createElement(UsageAnalysisScreen)
                        }
                        Route {
                            path = EScreens.GALLERY_SCREEN.mPath
                            element = createElement(GalleryScreen)
                        }
                        Route {
                            path = EScreens.SELECTABLE_WATCH_LIST_SCREEN.mPath
                            element = createElement(SelectableWatchListScreen)
                        }
                        Route {
                            path = EScreens.COOKIE_EXPLANATION_SCREEN.mPath
                            element = createElement(CookieExplanationScreen)
                        }
                    }
                }
            }
        }
        GlobalLoadingSpinner()
    }
}