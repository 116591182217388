package components.toast

import kotlin.js.Date
import kotlin.time.Duration.Companion.milliseconds

data class ToastItem(
    val mMessage: String,
    val mDurationInMillis: Int = 2000,
    var mIsOpen: Boolean = true,
    val mKey: String = Date.now().milliseconds.toString()
)
