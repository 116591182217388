package screens.colourFinder

import common.EConstants
import common.ECssClasses
import common.EEnvironmentVariables
import components.ButtonContent
import components.Toolbar
import components.burgerMenu.BurgerMenu
import components.colourChip.ColourChip
import components.loadingSpinner.LoadingSpinner
import core.AppContext
import core.enums.EImages
import core.enums.ELocalizationKeys
import core.enums.EPostMessageActions
import core.extensions.getValue
import core.io.dto.ColourCollectionDTO
import core.io.dto.ColourDTO
import core.io.dto.MinifiedColourDTO
import core.ionic.*
import core.localStorage.LocalStorageDAO
import core.localization.Localization
import core.util.exhaustive
import csstype.*
import kotlinext.js.asJsObject
import kotlinx.browser.window
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import org.w3c.dom.Element
import org.w3c.dom.HTMLIFrameElement
import org.w3c.dom.MessageEvent
import org.w3c.dom.events.Event
import react.*
import react.css.css
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.iframe
import react.dom.html.ReactHTML.img
import react.router.Navigate
import react.router.useParams
import screens.EScreens


external interface ColourFinderModuleScreenProps : Props {
    var mNavigate: String?
}

external class ResizeObserver(onResize: () -> Unit) {
    fun observe(element: Element)
}

val ColourFinderModuleScreen = FC<ColourFinderModuleScreenProps> { props ->

    val mContext by useContext(AppContext)
    var mColourCollection: ColourCollectionDTO? by useState(null)
    val mColorList = useMemo(mColourCollection) {
        mColourCollection?.mData ?: listOf(ColourDTO("0", 1, false, false, "", "", "#FFFFFF", "", "", "", ""))
    }
    var mTargetScreen: String? by useState(props.mNavigate)
    val mForwardNavigationEnabled: Boolean =
        useMemo(mColourCollection) { mColourCollection?.mData?.any { it.mFixed } ?: false }
    val mShuffleButtonEnabled = useMemo(mColourCollection) { mColourCollection?.mData?.any { !it.mFixed } ?: true }
    var mIsLoading by useState(true)

    val mSerializer = Json {
        ignoreUnknownKeys = true
        isLenient = true
    }

    val mParams = useParams()
    val mIframeRef = useRef<HTMLIFrameElement>(null)

    var mFrameWidth by useState(0)
    var mFrameHeight by useState(0)

    useEffect(mIframeRef) {
        mIframeRef.current?.let {
            ResizeObserver {
                mFrameWidth = it.clientWidth
                mFrameHeight = it.clientHeight
            }.observe(it)
        }
    }


    useEffectOnce {
        mContext.mLocalStorageReducer.component2().invoke(LocalStorageDAO.ELocalStorageAction.CLEAR_FIXED_LIST)
        mContext.mLocalStorageReducer.component2().invoke(LocalStorageDAO.ELocalStorageAction.CLEAR_SUGGESTED_LIST)
        val eventMethod: (Event) -> Unit = { event ->
            event as MessageEvent
            val target = event.data?.asJsObject().unsafeCast<String>()
            val dto = kotlin.runCatching { mSerializer.decodeFromString<ColourCollectionDTO>(target) }.getOrNull()

            when (dto?.mAction) {
                EPostMessageActions.SET_COLOURS -> {
                    mColourCollection = dto
                }

                EPostMessageActions.READY -> {
                    mIsLoading = false
                }

                EPostMessageActions.SHUFFLE -> {}
                EPostMessageActions.SET_HIGHLIGHT -> {}
                EPostMessageActions.EXPORT -> {}
                EPostMessageActions.TOGGLE_COLOUR -> {}
                null -> {}

                else -> {}
            }.exhaustive
        }
        window.addEventListener(type = "message", eventMethod)
        cleanup {
            window.removeEventListener(type = "message", eventMethod)
        }
    }

    BurgerMenu()
    IonPage {
        id = EConstants.BURGER_MENU_CONTENT.mId

        IonHeader {
            Toolbar()
        }

        IonContent {
            css(ECssClasses.BODY.mClassName) {}
            IonGrid {
                css(
                    classNames = arrayOf(
                        ECssClasses.VERTICAL_GRID.mClassName,
                        ECssClasses.NON_SCROLLABLE_GRID.mClassName
                    )
                ) {}
                IonRow {
                    css(ECssClasses.FULL_WIDTH_ROW.mClassName) {
                        flexGrow = FlexGrow(1.0)
                    }
                    IonCol {
                        css(ECssClasses.FULL_WIDTH_COL.mClassName) {
                        }
                        iframe {
                            css {
                                height = 100.pct
                                width = 100.pct
                                frameBorder = 0
                            }
                            id = "colourSelectorIframe"
                            ref = mIframeRef

                            src =
                                if (mFrameWidth > 0 && mFrameHeight > 0)
                                    "https://${EEnvironmentVariables.VEEUZE_HOST.getValue()}/api/colourselector.php?token=${mParams["token"]}&rectWidth=${mFrameWidth}&rectHeight=${mFrameHeight}"
                                else
                                    "about:blank"
                        }
                    }
                }
                IonRow {
                    IonCol {
                        button {
                            css(ECssClasses.ACTION_BUTTON.mClassName) {
                                borderRadius = 50.pct
                                width = 72.px
                                height = 72.px
                                borderWidth = 8.px
                                borderColor = NamedColor.white
                                borderStyle = LineStyle.solid
                                left = 50.pct - (72 / 2).px
                                top = (-35).px
                                alignItems = AlignItems.center
                                position = Position.absolute
                                display = Display.flex
                                justifyContent = JustifyContent.spaceAround

                            }
                            onClick = {
                                mIframeRef.current?.contentWindow?.postMessage(
                                    Json.encodeToString(
                                        ColourCollectionDTO(
                                            EPostMessageActions.SHUFFLE,
                                            null
                                        )
                                    ),
                                    "https://${EEnvironmentVariables.VEEUZE_HOST.getValue()}"
                                )
                            }
                            disabled = !mShuffleButtonEnabled
                            img {
                                css {
                                    height = 40.px
                                    width = 40.px
                                }
                                src = EImages.SHUFFLE_ICON.mRelativePath
                            }
                        }
                    }
                }
                IonRow {
                    css("ion-justify-content-center") {
                        justifyContent = JustifyContent.center
                    }
                    IonCol {
                        size = "12"
                        css {
                            marginTop = 42.px
                        }
                        div {
                            css {
                                display = Display.flex
                                justifyContent = JustifyContent.spaceBetween
                                padding = 0.px
                            }
                            mColorList.let { colourList ->
                                colourList.forEach { colour ->
                                    ColourChip {
                                        mColourDTO = colour
                                        mOnClick = {
                                            colourList.forEach { it.mHighlight = false }
                                            colour.mHighlight = !colour.mHighlight
                                            colour.mFixed = !colour.mFixed

                                            colourList.forEach {
                                                if (it.mUnique == colour.mUnique) {
                                                    it.mFixed = colour.mFixed
                                                }
                                            }
                                            mContext.mLocalStorageReducer.component2().invoke(
                                                LocalStorageDAO.ELocalStorageAction.ADD_OR_DELETE_FROM_FIXEDLIST(
                                                    MinifiedColourDTO(
                                                        colour.mUnique,
                                                        colour.mName,
                                                        colour.mArtNr,
                                                        colour.mColourCode,
                                                        colour.mColourCollection,
                                                        colour.mAdditionalInfo1,
                                                        colour.mAdditionalInfo2,
                                                        colour.mEanCode
                                                    )
                                                )
                                            )

                                            mColourCollection = ColourCollectionDTO(
                                                EPostMessageActions.SET_COLOURS,
                                                colourList
                                            )



                                            mIframeRef.current?.contentWindow?.postMessage(
                                                Json.encodeToString(
                                                    ColourCollectionDTO(
                                                        EPostMessageActions.SET_HIGHLIGHT,
                                                        colourList
                                                    )
                                                ),
                                                "https://${EEnvironmentVariables.VEEUZE_HOST.getValue()}"
                                            )
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                IonRow {
                    css(ECssClasses.BOTTOM_BUTTONS.mClassName) {}
                    IonCol {
                        css {
                            justifyContent = JustifyContent.spaceBetween
                            display = Display.flex
                            padding = 0.px
                        }
                        size = "12"
                        IonButton {
                            css(ECssClasses.SECONDARY_BUTTON.mClassName) {
                                width = 165.px
                            }
                            onClick = {
                                mTargetScreen = EScreens.COLOUR_FINDER_UPLOAD_SCREEN.mPath
                            }
                            ButtonContent {
                                mContent =
                                    Localization.getLocalizationKey(ELocalizationKeys.CORE_BACK.name)
                            }
                        }
                        IonButton {
                            css(ECssClasses.PRIMARY_BUTTON.mClassName) {
                                width = 165.px
                            }
                            onClick = {
                                mTargetScreen = EScreens.COLOUR_TONE_LIST_SCREEN.mPath
                            }

                            disabled = !mForwardNavigationEnabled
                            ButtonContent {
                                mContent =
                                    Localization.getLocalizationKey(ELocalizationKeys.S03_PRIMARY_BUTTON_CONTENT.name)
                            }
                        }
                    }
                }
            }
        }

        if (mIsLoading) {
            LoadingSpinner()
        }

        mTargetScreen?.let {
            Navigate {
                to = it
            }
        }
    }
}

