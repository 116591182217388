package components.tour

import common.EColors
import common.ECssClasses
import common.ETourColourItemState
import core.enums.EImages
import core.enums.ELocalizationKeys
import core.extensions.isMobile
import core.extensions.toColor
import core.identifier.Identifiers
import core.localization.Localization
import csstype.*
import kotlinx.browser.document
import kotlinx.browser.window
import react.FC
import react.Props
import react.css.css
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.p
import kotlin.math.min

external interface ColourExpandableItemProps : Props {
    var step: Int
    var colorValue: Color
    var colorSelected: Boolean
    var itemState: ETourColourItemState
    var absolutePositionLeft: Length
    var absolutePositionBottom: Length
}

val ColourExpandableItem = FC<ColourExpandableItemProps> { props ->

    div {
        css(ECssClasses.TOUR_COLOUR_EXPANDABLE_ITEM.mClassName, "step_${props.step}") {
            left = props.absolutePositionLeft
            bottom = props.absolutePositionBottom
            when (props.itemState) {
                ETourColourItemState.SMALL_DOT -> {
                    width = 24.px
                    height = 24.px
                    borderRadius = 50.pct
                    border = "${3.px} solid ${NamedColor.white}".unsafeCast<Border>()
                    backgroundColor = props.colorValue.unsafeCast<BackgroundColor>()
                }
                ETourColourItemState.SMALL_SQUARE -> {
                    width = 56.px
                    height = 56.px
                    borderRadius = 25.pct
                    border = "${if (props.colorSelected) 10.px else 3.px} solid ${NamedColor.white}".unsafeCast<Border>()
                    backgroundColor = props.colorValue.unsafeCast<BackgroundColor>()
                    boxShadow = "${0.px} ${0.px} ${10.px} ${0.px} ${rgba(0,0,0,0.25)}".unsafeCast<BoxShadow>()
                }
                ETourColourItemState.LARGE_SQUARE -> {
                    width = 80.pct
                    height = if (window.isMobile()) 44.px else 64.px
                    borderRadius = 4.px
                    backgroundColor = props.colorValue.unsafeCast<BackgroundColor>()
                    boxShadow = "${0.px} ${0.px} ${10.px} ${0.px} ${rgba(0,0,0,0.25)}".unsafeCast<BoxShadow>()
                }
                ETourColourItemState.INVISIBLE -> {  }
            }
            transition = "all 0.5s ease-in-out".unsafeCast<Transition>()
        }
        div {
            css {
                display = Display.flex
                flexDirection = FlexDirection.row
                flexWrap = FlexWrap.nowrap
                justifyContent = JustifyContent.spaceBetween
                alignItems = AlignItems.center
                padding = if (window.isMobile()) 4.px else 8.px
                opacity = if (props.itemState == ETourColourItemState.LARGE_SQUARE) Opacity(1.0) else Opacity(0.0)
                transition = "all 0.5s ease-in-out".unsafeCast<Transition>()
            }
            img {
                css {
                    maxWidth = if (window.isMobile()) 36.px else 48.px
                    maxHeight = if (window.isMobile()) 36.px else 48.px
                    flexGrow = FlexGrow(2.0)
                }
                src = when (props.colorValue) {
                    EColors.TOUR_COLOR_1.toColor() -> EImages.TOUR_STAGE_4_BUCKET_COLOR_1.mRelativePath
                    EColors.TOUR_COLOR_2.toColor() -> EImages.TOUR_STAGE_4_BUCKET_COLOR_2.mRelativePath
                    EColors.TOUR_COLOR_3.toColor() -> EImages.TOUR_STAGE_4_BUCKET_COLOR_3.mRelativePath
                    EColors.TOUR_COLOR_4.toColor() -> EImages.TOUR_STAGE_4_BUCKET_COLOR_4.mRelativePath
                    EColors.TOUR_COLOR_5.toColor() -> EImages.TOUR_STAGE_4_BUCKET_COLOR_5.mRelativePath
                    else -> EImages.TOUR_STAGE_4_BUCKET_COLOR_1.mRelativePath
                }
            }
            div {
                css {
                    width = 50.pct
                    flexGrow = FlexGrow(1.0)
                }
                div {
                    css {
                        height = 11.px
                        width = "clamp(${20.pct}, ${148.px}, ${70.pct})".unsafeCast<Width>()
                        margin = if (window.isMobile()) 4.px else 8.px
                        backgroundColor = NamedColor.white
                        borderRadius = 4.px
                    }
                }
                div {
                    css {
                        height = 7.px
                        width = "clamp(${10.pct}, ${65.px}, ${30.pct})".unsafeCast<Width>()
                        margin = if (window.isMobile()) 4.px else 8.px
                        backgroundColor = NamedColor.white
                        borderRadius = 4.px
                    }
                }
            }
            div {
                css {
                    flexGrow = FlexGrow(2.0)
                }
                div {
                    css {
                        display = Display.flex
                        flexDirection = FlexDirection.column
                        flexWrap = FlexWrap.nowrap
                        justifyContent = JustifyContent.spaceBetween
                    }
                    div {
                        css {
                            display = Display.flex
                            flexDirection = FlexDirection.row
                            flexWrap = FlexWrap.nowrap
                            justifyContent = JustifyContent.spaceAround
                        }
                        img {
                            css {
                                height = if (window.isMobile()) 16.px else 24.px
                                width = if (window.isMobile()) 16.px else 24.px
                            }
                            src = EImages.WATCHLIST_ICON(EImages.EColor.WHITE, EImages.EType.OUTLINED).mRelativePath
                        }
                        img {
                            css {
                                height = if (window.isMobile()) 16.px else 24.px
                                width = if (window.isMobile()) 16.px else 24.px
                            }
                            src = EImages.DELETE_ICON_WHITE.mRelativePath
                        }
                    }
                    p {
                        css(if (props.colorSelected) "${ECssClasses.TOUR_STAGE_4_BUY_OPTION.mClassName} step_${props.step}" else "") {
                            color = NamedColor.white
                            fontWeight = FontWeight.bolder
                            textAlign = TextAlign.center
                            margin = 2.px
                            if (window.isMobile()) {
                                fontSize = 11.px
                            }
                        }
                        +Localization.getLocalizationKey(ELocalizationKeys.BUY_OPTIONS)
                    }
                }
            }
        }
    }
}
