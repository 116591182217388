package core.identifier

object Identifiers {
    const val TOUR_STAGE_1_BACKGROUND = "tour_stage_1_background"
    const val TOUR_STAGE_1_HAND_WITH_PHONE = "tour_stage_1_hand_with_phone"
    const val TOUR_STAGE_1_HAND_WITH_PHONE_FLASH = "tour_stage_1_hand_with_phone_flash"
    const val TOUR_STAGE_1_CAM_INDICATOR_UNPRESSED = "tour_stage_1_cam_indicator_unpressed"
    const val TOUR_STAGE_1_CAM_INDICATOR_PRESSED = "tour_stage_1_cam_indicator_pressed"

    const val TOUR_BOTTOM_CONTENT = "tour_bottom_content"
}