package components.items

import core.AppContext
import core.enums.EImages
import core.ionic.EmptyProps
import core.ionic.IonButton
import csstype.px
import react.FC
import react.css.css
import react.dom.html.ReactHTML
import react.router.Navigate
import react.router.useNavigate
import react.useContext
import react.useState
import screens.EScreens

val BackButtonIcon = FC<EmptyProps> { props ->

    val mSrc = EImages.ARROW_BACK.mRelativePath

    val mNavigate = useNavigate()

    IonButton {
        fill = "clear"
        shape = "round"
        ReactHTML.img {
            css {
                height = 24.px
            }
            src = mSrc
        }
        onClick = {
            mNavigate.invoke(-1)
        }
    }
}