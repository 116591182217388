package components.tour

import core.enums.EImages
import csstype.*
import react.FC
import react.Props
import react.css.css
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.useState

external interface NotchIndicatorItemProps : Props {
    var iconSrc: String
}

val NotchIndicatorItem = FC<NotchIndicatorItemProps> { props ->

    val mIconSrc by useState(props.iconSrc)

    div {
        css {
            width = 35.px
            height = 35.px
        }
        div {
            css {
                position = Position.absolute
                top = 0.px
                left = 0.px
                width = 35.px
                height = 35.px
                borderRadius = 50.pct
                backgroundColor = rgba(162, 166, 175, 1.0)
            }
        }
        img {
            css {
                position = Position.absolute
                top = 50.pct
                left = 50.pct
                transform = "translate(-50%, -50%)".unsafeCast<Transform>()
            }
            src = mIconSrc
        }
    }
}