package components.toast

import core.ionic.IonToast
import react.*

external interface BottomToastProps : Props {
    var mToastItem: ToastItem
}

val BottomToast = FC<BottomToastProps> { props ->

    val mToastItem = props.mToastItem

    IonToast {
        key = mToastItem.mKey
        isOpen = mToastItem.mIsOpen
        message = mToastItem.mMessage
        duration = mToastItem.mDurationInMillis
        onDidDismiss = {
            mToastItem.mIsOpen = false
        }
    }
}