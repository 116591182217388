package core.io.dto

import core.enums.EPostMessageActions
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class MinifiedColourCollectionDTO(
    @SerialName("action")
    val mAction: EPostMessageActions,

    @SerialName("data")
    val mData: List<MinifiedColourDTO>? = null,
)
