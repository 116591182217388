package screens.static

import common.EColors
import common.ECssClasses
import common.ETourColourItemState
import common.ETourIndicators
import common.animation.TourAnimationConstants
import common.animation.TourAnimationState
import common.animation.TourAnimations
import common.animation.Transition
import components.ButtonContent
import components.tour.ColourExpandableItem
import components.tour.NotchIndicatorItem
import core.enums.EImages
import core.enums.ELocalizationKeys
import core.extensions.isMobile
import core.extensions.toColor
import core.extensions.useCoroutineScope
import core.identifier.Identifiers
import core.ionic.IonButton
import core.ionic.IonContent
import core.ionic.IonPage
import core.localization.Localization
import csstype.*
import csstype.Position.Companion.relative
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import module.home.TourColourItem
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLImageElement
import org.w3c.dom.asList
import org.w3c.dom.events.Event
import org.w3c.dom.get
import react.*
import react.css.css
import react.dom.events.TouchEvent
import react.dom.html.ImgLoading
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span
import react.router.Navigate
import screens.EScreens
import setUnsafeInnerHTML


external interface HomeScreenProps : Props {
}

val HomeScreen = FC<HomeScreenProps> { props ->

    val mTourAnimations = TourAnimations()
    val mScope = useCoroutineScope()

    var mNavigate: String? by useState(null)

    var mChangeTransitionJob by useState<Job>()
    var mChangeStepJob by useState<Job>()

    var mLastStage by useState(0)
    var mCurrentStage by useState(0)
    var mStepIterator by useState(0)

    var mNotchIconSrc by useState("")
    var mCurrentTransition by useState(mTourAnimations.mTransitions.first())

    var mStageAnimationStarted by useState(false)

    val mIsBackButtonVisible = useMemo(mCurrentStage) {
        mCurrentStage > 0
    }

    val mCurrentStep = useMemo(mCurrentStage, mStepIterator) {
        mCurrentStage.times(4).plus(mStepIterator)
    }

    val mCssStep = useMemo(mCurrentTransition) {
        mTourAnimations.mTransitions.indexOf(mCurrentTransition)
    }

    useEffect(mCurrentStage) {
        if(mCurrentStage < 0) {
            mCurrentStage = 0
        } else if(mCurrentStage >= ETourIndicators.values().size) {
            mNavigate = EScreens.COLOUR_FINDER_UPLOAD_SCREEN.mPath
        } else {
            mScope.launch {
                delay(400)
                mNotchIconSrc = ETourIndicators.values()
                    .getOrNull(mCurrentStage)
                    ?.mImageSrc ?: ""
            }
        }
    }

    useEffect(mCurrentStep) {
        updateDOM(mCurrentTransition)
    }

    useEffect(mCurrentStep, mStageAnimationStarted) {
        if (mStageAnimationStarted) {
            mChangeTransitionJob = mScope.launch {
                mTourAnimations.mTransitions.getOrNull(mCurrentStep)?.let {
                    delay(it.mDelay)
                    mCurrentTransition = it
                }
            }
        }
    }

    useEffect(mCurrentTransition) {
        updateDOM(mCurrentTransition)
        mChangeStepJob = mScope.launch {
            delay(mCurrentTransition.mDuration)
            if (mCurrentTransition.mNextState == null) {
                mStepIterator++
            }
        }
    }

    var mCurrentColourState by useState(
        Pair(
            ETourColourItemState.INVISIBLE,
            mutableListOf(
                TourColourItem(EColors.TOUR_COLOR_1.toColor(), 36.pct, calculateColourItemHeightPosition(mCurrentTransition.mData.mScale, 0.70)),
                TourColourItem(EColors.TOUR_COLOR_2.toColor(), 53.pct, calculateColourItemHeightPosition(mCurrentTransition.mData.mScale, 0.85)),
                TourColourItem(EColors.TOUR_COLOR_3.toColor(), 57.pct, calculateColourItemHeightPosition(mCurrentTransition.mData.mScale, 0.50)),
                TourColourItem(EColors.TOUR_COLOR_4.toColor(), 40.pct, calculateColourItemHeightPosition(mCurrentTransition.mData.mScale, 0.30)),
                TourColourItem(EColors.TOUR_COLOR_5.toColor(), 52.pct, calculateColourItemHeightPosition(mCurrentTransition.mData.mScale, 0.25))
            )
        )
    )

    val mColourState: Pair<ETourColourItemState, MutableList<TourColourItem>> = useMemo(mCssStep) {
        when (mCssStep) {
            1 -> {
                mCurrentColourState.copy(
                    first = ETourColourItemState.INVISIBLE,
                    second = mCurrentColourState.second.onEach {
                        it.mColorSelected = false
                    }
                )
            }
            mTourAnimations.mTransitions.indexOfFirst { it.mData.mColourItemState == ETourColourItemState.SMALL_DOT } -> {
                mCurrentColourState.copy(
                    first = ETourColourItemState.SMALL_DOT,
                    second = mCurrentColourState.second.onEach {
                        it.mColorSelected = false
                        it.mAbsolutePositionLeft = when (it.mColorValue) {
                            EColors.TOUR_COLOR_1.toColor() -> 36.pct
                            EColors.TOUR_COLOR_2.toColor() -> 53.pct
                            EColors.TOUR_COLOR_3.toColor() -> 57.pct
                            EColors.TOUR_COLOR_4.toColor() -> 40.pct
                            EColors.TOUR_COLOR_5.toColor() -> 52.pct
                            else -> 0.pct
                        }
                        it.mAbsolutePositionBottom = calculateColourItemHeightPosition(
                            mCurrentTransition.mData.mScale,
                            when (it.mColorValue) {
                                EColors.TOUR_COLOR_1.toColor() -> 0.70
                                EColors.TOUR_COLOR_2.toColor() -> 0.85
                                EColors.TOUR_COLOR_3.toColor() -> 0.50
                                EColors.TOUR_COLOR_4.toColor() -> 0.30
                                EColors.TOUR_COLOR_5.toColor() -> 0.25
                                else -> 0.0
                            }
                        )
                    }
                )
            }
            mTourAnimations.mTransitions.indexOfFirst {
                it.mData.mColourItemState == ETourColourItemState.SMALL_SQUARE
            },
            mTourAnimations.mTransitions.indexOfFirst {
                it.mData.mIsMyColoursBackgroundVisible
            }.plus(1) -> {
                mCurrentColourState.copy(
                    first = ETourColourItemState.SMALL_SQUARE,
                    second = mCurrentColourState.second.onEach {
                        it.mAbsolutePositionLeft = when (it.mColorValue) {
                            EColors.TOUR_COLOR_1.toColor() -> 10.pct
                            EColors.TOUR_COLOR_2.toColor() -> 30.pct
                            EColors.TOUR_COLOR_3.toColor() -> 50.pct
                            EColors.TOUR_COLOR_4.toColor() -> 70.pct
                            EColors.TOUR_COLOR_5.toColor() -> 90.pct
                            else -> 0.pct
                        }
                        it.mAbsolutePositionBottom = calculateColourItemSmallSquareHeightPosition()
                    }
                )
            }
            mTourAnimations.mTransitions.indexOfFirst { it.mData.mIsMyColoursBackgroundVisible }.plus(2) -> {
                mCurrentColourState.copy(
                    second = mCurrentColourState.second.onEach {
                        it.mColorSelected = false
                        if (it.mColorValue == EColors.TOUR_COLOR_1.toColor()) {
                            it.mColorSelected = true
                        }
                    }
                )
            }
            mTourAnimations.mTransitions.indexOfFirst { it.mData.mIsMyColoursBackgroundVisible }.plus(3) -> {
                mCurrentColourState.copy(
                    second = mCurrentColourState.second.onEach {
                        it.mColorSelected = false
                        if (it.mColorValue == EColors.TOUR_COLOR_4.toColor()) {
                            it.mColorSelected = true
                        }
                    }
                )
            }
            mTourAnimations.mTransitions.indexOfFirst { it.mData.mIsWholeBackgroundBlurred } -> {
                mCurrentColourState.copy(
                    second = mCurrentColourState.second.onEach {
                        it.mColorSelected = false
                    }
                )
            }
            mTourAnimations.mTransitions.indexOfFirst { it.mData.mIsWholeBackgroundBlurred }.plus(1) -> {
                mCurrentColourState.copy(
                    second = mCurrentColourState.second.onEach {
                        it.mColorSelected = false
                        it.mAbsolutePositionLeft = 10.pct
                        it.mAbsolutePositionBottom = if (window.isMobile()) {
                            when (it.mColorValue) {
                                EColors.TOUR_COLOR_1.toColor() -> 80.pct
                                EColors.TOUR_COLOR_2.toColor() -> 80.pct.minus(58.times(1).px)
                                EColors.TOUR_COLOR_3.toColor() -> 80.pct.minus(58.times(2).px)
                                EColors.TOUR_COLOR_4.toColor() -> 80.pct.minus(58.times(3).px)
                                EColors.TOUR_COLOR_5.toColor() -> 80.pct.minus(58.times(4).px)
                                else -> 0.pct
                            }
                        } else {
                            when (it.mColorValue) {
                                EColors.TOUR_COLOR_1.toColor() -> 75.pct
                                EColors.TOUR_COLOR_2.toColor() -> 65.pct
                                EColors.TOUR_COLOR_3.toColor() -> 55.pct
                                EColors.TOUR_COLOR_4.toColor() -> 45.pct
                                EColors.TOUR_COLOR_5.toColor() -> 35.pct
                                else -> 0.pct
                            }
                        }
                    }
                )
            }
            mTourAnimations.mTransitions.indexOfFirst { it.mData.mIsWholeBackgroundBlurred }.plus(2) -> {
                mCurrentColourState.copy(
                    first = ETourColourItemState.LARGE_SQUARE,
                    second = mCurrentColourState.second.onEach {
                        it.mAbsolutePositionLeft = 50.pct
                    }
                )
            }
            mTourAnimations.mTransitions.indexOfFirst { it.mData.mIsColourItemBuyOptionScaled } -> {
                mCurrentColourState.copy(
                    second = mCurrentColourState.second.onEach {
                        if (it.mColorValue == EColors.TOUR_COLOR_1.toColor()) {
                            it.mColorSelected = true
                        }
                    }
                )
            }
            else -> {
                mCurrentColourState
            }
        }
    }

    useEffect(mColourState) {
        mCurrentColourState = mColourState
    }

    fun navigate(with: () -> Unit) {
        mStageAnimationStarted = false
        mChangeTransitionJob?.cancel()
        mChangeStepJob?.cancel()
        mStepIterator = 1
        mLastStage = mCurrentStage
        with()
        mStageAnimationStarted = true
    }

    fun navigateToNextStage() {
        navigate {
            if (mCurrentStage < ETourIndicators.values().size) {
                mCurrentStage++
            }
        }
    }

    fun navigateToPreviousStage() {
        navigate {
            if (mCurrentStage > 0) {
                mCurrentStage--
            }
        }
    }

    var mTouchStart = 0
    useEffect {
        val eventMethodStart: (Event) -> Unit = { event ->
            event as TouchEvent<*>
            val target = event.targetTouches
            mTouchStart = target[0]?.clientX ?: 0
        }

        val eventMethodEnd: (Event) -> Unit = { event ->
            event as TouchEvent<*>
            val target = event.changedTouches
            if(mTouchStart - (target[0]?.clientX ?: 0) > 150) {
                navigateToNextStage()
            }

            if(mTouchStart - (target[0]?.clientX ?: 0) < -150) {
                navigateToPreviousStage()
            }
        }
        document.body?.addEventListener(type = "touchstart", eventMethodStart)
        document.body?.addEventListener(type = "touchend", eventMethodEnd)
        cleanup {
            document.body?.removeEventListener(type = "touchstart", eventMethodStart)
            document.body?.removeEventListener(type = "touchend", eventMethodEnd)
        }
    }

    window.onresize = {
        updateDOM(mCurrentTransition)
    }

    window.onload = {
        updateDOM(mCurrentTransition)
    }

    IonPage {
        IonContent {
            div {
                css(ECssClasses.ANIMATION_CONTAINER.mClassName) {
                    height = 100.pct
                    width = 100.pct
                    position = relative
                }

                div {
                    css(ECssClasses.TOUR_STAGE_4_BACKGROUND.mClassName, "step_$mCssStep") {  }
                    img {
                        id = Identifiers.TOUR_STAGE_1_BACKGROUND
                        css(ECssClasses.TOUR_STAGE_1_BACKGROUND.mClassName, "step_$mCssStep") {
                            minHeight = 100.pct
                                .minus((document.getElementById(Identifiers.TOUR_BOTTOM_CONTENT)?.clientHeight ?: 0).px)
                            bottom = (document.getElementById(Identifiers.TOUR_BOTTOM_CONTENT)?.clientHeight ?: 0).px
                            opacity = if (mCssStep in 0..8) Opacity(1.0) else Opacity(0.0)
                        }
                        src = EImages.TOUR_STAGE_1_BACKGROUND.mRelativePath
                    }

                    img {
                        id = Identifiers.TOUR_STAGE_1_BACKGROUND
                        css(ECssClasses.TOUR_STAGE_1_BACKGROUND.mClassName, "step_$mCssStep") {
                            minHeight = 100.pct
                                .minus((document.getElementById(Identifiers.TOUR_BOTTOM_CONTENT)?.clientHeight ?: 0).px)
                            bottom = (document.getElementById(Identifiers.TOUR_BOTTOM_CONTENT)?.clientHeight ?: 0).px
                            opacity = if (mCssStep in 9..mTourAnimations.mTransitions.size) Opacity(1.0) else Opacity(0.0)
                        }
                        src = EImages.TOUR_STAGE_3_FARBRAUM_1.mRelativePath
                    }

                    div {
                        css(
                            ECssClasses.TOUR_STAGE_1_HAND_WITH_PHONE_FLASH_BACKGROUND.mClassName,
                            "step_$mCssStep"
                        ) {
                            bottom = calculateHandWithPhoneBottom()
                            height = calculateFlashWidthAsDouble().times(1.5).times(2).px
                            backgroundImage =
                                "radial-gradient(circle ${calculateFlashWidthAsDouble().times(1.5).px}, white ${1.pct}, transparent ${80.pct})".unsafeCast<BackgroundImage>()
                        }
                    }


                    img {
                        id = Identifiers.TOUR_STAGE_1_HAND_WITH_PHONE
                        css(ECssClasses.TOUR_STAGE_1_HAND_WITH_PHONE.mClassName, "step_$mCssStep") {
                            opacity = if (mCssStep in 0..8) Opacity(1.0) else Opacity(0.0)
                            if (mCurrentStep in 2..4 || (mCurrentStep == 1 && mLastStage == 1)) {
                                transition = "all ${mTourAnimations.mTransitions.getOrNull(mCssStep)?.mDuration?.ms} ease".unsafeCast<csstype.Transition>()
                            }
                        }
                        loading = ImgLoading.eager
                        src = EImages.TOUR_STAGE_1_HAND_WITH_PHONE_FOREST.mRelativePath
                        onLoad = {
                            mStageAnimationStarted = true
                            updateDOM(mCurrentTransition)
                            visibilityCamIndicator(Visibility.visible)
                        }
                        onTransitionEnd = {
                            updateDOM(mCurrentTransition)
                        }
                    }

                    img {
                        css(ECssClasses.TOUR_STAGE_1_HAND_WITH_PHONE.mClassName, "step_$mCssStep") {
                            opacity = if (mCssStep == 9) Opacity(1.0) else Opacity(0.0)
                        }
                        loading = ImgLoading.lazy
                        src = EImages.TOUR_STAGE_3_HAND_WITH_PHONE_FARBRAUM_1.mRelativePath
                        onTransitionEnd = {
                            updateDOM(mCurrentTransition)
                        }
                    }

                    img {
                        css(ECssClasses.TOUR_STAGE_1_HAND_WITH_PHONE.mClassName, "step_$mCssStep") {
                            opacity = if (mCssStep == 10) Opacity(1.0) else Opacity(0.0)
                        }
                        loading = ImgLoading.lazy
                        src = EImages.TOUR_STAGE_3_HAND_WITH_PHONE_FARBRAUM_2.mRelativePath
                        onTransitionEnd = {
                            updateDOM(mCurrentTransition)
                        }
                    }

                    img {
                        css(ECssClasses.TOUR_STAGE_1_HAND_WITH_PHONE.mClassName, "step_$mCssStep") {
                            opacity = if (mCssStep in 11..mTourAnimations.mTransitions.lastIndex.plus(1)) Opacity(1.0) else Opacity(0.0)
                        }
                        loading = ImgLoading.lazy
                        src = EImages.TOUR_STAGE_3_HAND_WITH_PHONE_FARBRAUM_3.mRelativePath
                        onTransitionEnd = {
                            updateDOM(mCurrentTransition)
                        }
                    }

                    img {
                        id = Identifiers.TOUR_STAGE_1_CAM_INDICATOR_UNPRESSED
                        css(ECssClasses.TOUR_STAGE_1_CAM_INDICATOR.mClassName, "step_$mCssStep") {
                            visibility = Visibility.hidden
                            opacity = if (mCssStep == 2 || mCssStep >= 4) Opacity(0.0) else Opacity(1.0)
                            if (mLastStage != 0 || mCssStep > 1) {
                                transition = "all ${mTourAnimations.mTransitions.getOrNull(mCssStep)?.mDuration?.ms} ease".unsafeCast<csstype.Transition>()
                            }
                        }
                        loading = ImgLoading.eager
                        src = EImages.TOUR_STAGE_1_CAM_INDICATOR.mRelativePath
                    }

                    img {
                        id = Identifiers.TOUR_STAGE_1_CAM_INDICATOR_PRESSED
                        css(ECssClasses.TOUR_STAGE_1_CAM_INDICATOR.mClassName, "step_$mCssStep") {
                            visibility = if (mCssStep == 2) Visibility.visible else Visibility.hidden
                        }
                        loading = ImgLoading.lazy
                        src = EImages.TOUR_STAGE_1_CAM_INDICATOR_PRESSED.mRelativePath
                    }

                    div {
                        id = Identifiers.TOUR_STAGE_1_HAND_WITH_PHONE_FLASH
                        css(ECssClasses.TOUR_STAGE_1_HAND_WITH_PHONE_FLASH.mClassName, "step_$mCssStep") {  }
                    }

                    div {
                        css(ECssClasses.TOUR_STAGE_2_COLOUR_EXPANDABLE_ITEM_BACKGROUND.mClassName, "step_$mCssStep") {
                            bottom = calculateColourItemSmallSquareHeightPosition()
                        }
                        div {
                            css {
                                paddingTop = 15.px
                                fontSize = 16.px
                                fontFamily = "Roboto".unsafeCast<FontFamily>()
                                fontWeight = FontWeight.bolder
                            }
                            +Localization.getLocalizationKey(ELocalizationKeys.S01_MY_COLOR_PREVIEW_TITLE)
                        }
                    }
                }

                div {
                    css(ECssClasses.TOUR_STAGE_4_HEADLINE.mClassName, "step_$mCssStep") {  }
                    p {
                        css {
                            fontSize = 16.px
                            fontWeight = FontWeight.bold
                        }
                        +Localization.getLocalizationKey(ELocalizationKeys.S01_PRODUCT_LIST_TITLE)
                    }
                }

                mCurrentColourState.let { state ->
                    state.second.forEach {
                        ColourExpandableItem {
                            step = mCssStep
                            colorValue = it.mColorValue
                            colorSelected = it.mColorSelected
                            itemState = state.first
                            absolutePositionLeft = it.mAbsolutePositionLeft
                            absolutePositionBottom = it.mAbsolutePositionBottom
                        }
                    }
                }

                div {
                    id = Identifiers.TOUR_BOTTOM_CONTENT
                    css(ECssClasses.TOUR_BACKGROUND.mClassName) {
                        gridTemplateRows = "repeat(4, auto)".unsafeCast<GridTemplateRows>()
                        gridTemplateColumns = "repeat(2, 1fr)".unsafeCast<GridTemplateColumns>()
                        paddingTop = 59.px
                    }

                    div {
                        css(ECssClasses.TOUR_TOP_ELLIPSE.mClassName) {
                            zIndex = ZIndex(1)
                        }
                    }

                    div {
                        css(ECssClasses.TOUR_ELLIPSE_CIRCLE.mClassName) {
                            zIndex = ZIndex(2)
                        }
                        setUnsafeInnerHTML("""
                            <svg xmlns="http://www.w3.org/2000/svg" width="84" height="84" viewBox="0 0 84 84" fill="none">
                                <circle cx="42" cy="42" r="42" fill="#FF6B00">
                                    ${
                                        if (mStepIterator == 1 && (mCurrentStage != 0 || mLastStage != 0)) {
                                            """<animate id="notchOuterCircle" attributeName="r" values="42; 0; 42" dur="${0.8.s}" restart="whenNotActive"/>"""
                                        } else ""
                                    }
                                </circle>
                            </svg>
                        """.trimIndent())
                    }

                    div {
                        css(ECssClasses.TOUR_ELLIPSE_CIRCLE.mClassName) {
                            zIndex = ZIndex(3)
                        }
                        setUnsafeInnerHTML("""
                            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                                <circle cx="32" cy="32" r="32" fill="#E92120">
                                    ${
                                        if (mStepIterator == 1 && (mCurrentStage != 0 || mLastStage != 0)) {
                                            """<animate id="notchInnerCircle" attributeName="r" values="32; 0; 32" dur="${0.8.s}" restart="whenNotActive"/>"""
                                        } else ""
                                    }
                                </circle>
                            </svg>
                        """.trimIndent())
                    }

                    div {
                        css(ECssClasses.TOUR_ELLIPSE_CIRCLE.mClassName) {
                            zIndex = ZIndex(4)
                        }
                        img {
                            css("${ECssClasses.TOUR_ELLIPSE_ICON.mClassName}_currentStage_${mCurrentStage}_lastStage_${mLastStage}") {  }
                            src = mNotchIconSrc
                        }
                    }

                    ETourIndicators.values().sortedArrayDescending().forEachIndexed { index, indicator ->
                        div {
                            css(ECssClasses.NOTCH_INDICATOR.mClassName, "currentStageIndex_${mCurrentStage}_lastStageIndex_${mLastStage}_indicator_${index}") {  }
                            NotchIndicatorItem {
                                iconSrc = indicator.mImageSrc
                            }
                        }
                    }

                    span {
                        css {
                            gridRow = "1".unsafeCast<GridRow>()
                            gridColumn = "1 / -1".unsafeCast<GridColumn>()
                            margin = "0px 32px 8px".unsafeCast<Margin>()
                            textAlign = TextAlign.center
                            fontSize = 24.px
                            fontWeight = FontWeight.bolder
                            fontFamily = "Roboto".unsafeCast<FontFamily>()
                        }
                        val titleKey = when (mCurrentStage) {
                            0 -> ELocalizationKeys.S01_TITLE_1.name
                            1 -> ELocalizationKeys.S01_TITLE_2.name
                            2 -> ELocalizationKeys.S01_TITLE_3.name
                            3 -> ELocalizationKeys.S01_TITLE_4.name
                            else -> ""
                        }
                        +Localization.getLocalizationKey(titleKey)
                    }
                    span {
                        css {
                            gridRow = "2".unsafeCast<GridRow>()
                            gridColumn = "1 / -1".unsafeCast<GridColumn>()
                            minHeight = 36.px
                            margin = "8px 32px 8px".unsafeCast<Margin>()
                            textAlign = TextAlign.center
                            fontSize = 15.px
                            fontFamily = "Roboto".unsafeCast<FontFamily>()
                        }
                        val textKey = when (mCurrentStage) {
                            0 -> ELocalizationKeys.S01_TEXT_1.name
                            1 -> ELocalizationKeys.S01_TEXT_2.name
                            2 -> ELocalizationKeys.S01_TEXT_3.name
                            3 -> ELocalizationKeys.S01_TEXT_4.name
                            else -> ""
                        }
                        setUnsafeInnerHTML(Localization.getLocalizationKey(textKey))
                    }
                    IonButton {
                        css(ECssClasses.SECONDARY_BUTTON.mClassName) {
                            display = if (mIsBackButtonVisible) Display.flex else Display.none
                            gridRow = "3".unsafeCast<GridRow>()
                            gridColumn = "1 / 2".unsafeCast<GridColumn>()
                            color = EColors.SECONDARY_BUTTON.toColor()
                            justifySelf = JustifySelf.center
                            marginLeft = 32.px
                            marginRight = 4.px
                            width = (100.pct - 32.px - 4.px)
                            borderColor = EColors.SECONDARY_BUTTON.toColor()
                        }
                        div {
                            css {
                                display = Display.flex
                                width = 100.pct
                                alignItems = AlignItems.center
                            }
                            span {
                                css {
                                    flexGrow = FlexGrow(1.0)
                                }
                                +Localization.getLocalizationKey(ELocalizationKeys.S01_BUTTON_BACK)
                            }
                        }
                        fill = "outline"
                        onClick = {
                            navigateToPreviousStage()
                        }
                    }
                    IonButton {
                        css(ECssClasses.PRIMARY_BUTTON.mClassName) {
                            gridRow = "3".unsafeCast<GridRow>()
                            gridColumn = "${if (mIsBackButtonVisible) 2 else 1} / 3".unsafeCast<GridColumn>()
                            color = NamedColor.white
                            justifySelf = JustifySelf.center
                            marginLeft = if (mIsBackButtonVisible) 4.px else 32.px
                            marginRight = 32.px
                            width = (100.pct - 32.px - if (mIsBackButtonVisible) 4.px else 32.px)
                        }
                        div {
                            css {
                                display = Display.flex
                                width = 100.pct
                                alignItems = AlignItems.center
                            }
                            span {
                                css {
                                    flexGrow = FlexGrow(1.0)
                                }
                                +Localization.getLocalizationKey(ELocalizationKeys.S01_BUTTON_NEXT)
                            }
                        }
                        fill = "solid"
                        onClick = {
                            navigateToNextStage()
                        }
                    }
                    IonButton {
                        css {
                            gridRow = "4".unsafeCast<GridRow>()
                            gridColumn = "1 / 3".unsafeCast<GridColumn>()
                            color = EColors.ORANGE.toColor()
                            justifySelf = JustifySelf.center
                            marginTop = 8.px
                            marginBottom = 16.px
                        }
                        ButtonContent {
                            mContent =
                                Localization.getLocalizationKey(ELocalizationKeys.S01_BUTTON_SKIP)
                        }
                        fill = "clear"
                        onClick = {
                            mNavigate = EScreens.COLOUR_FINDER_UPLOAD_SCREEN.mPath
                        }
                    }

                }
            }
        }
    }

    mNavigate?.let {
        Navigate {
            to = it
        }
    }
}

fun setInterval(timeMillis: Long, handler: () -> Unit): Job = GlobalScope.launch {
    while (true) {
        delay(timeMillis)
        handler()
    }
}

fun calculateHandWithPhoneHeight(): Length {
    return document.getElementById(Identifiers.TOUR_BOTTOM_CONTENT)?.let { bottomContentElement ->
        100.pct.minus(bottomContentElement.clientHeight.px)
    } ?: 100.pct
}

fun calculateHandWithPhoneBottom(): Length {
    val bottomElementHeight = document.getElementById(Identifiers.TOUR_BOTTOM_CONTENT)?.clientHeight ?: 0
    val handWithPhoneHeight = document.getElementById(Identifiers.TOUR_STAGE_1_HAND_WITH_PHONE)?.clientHeight ?: 0
    return bottomElementHeight.minus(handWithPhoneHeight.div(4)).px
}

fun calculateHandWithPhoneVisibility(): Visibility {
    return document.getElementById(Identifiers.TOUR_BOTTOM_CONTENT)?.let {
        if (it.clientHeight > 0) Visibility.visible else Visibility.hidden
    } ?: Visibility.hidden
}

fun calculateHandWithPhoneContentVisibility(): Visibility {
    return (document.getElementById(Identifiers.TOUR_STAGE_1_HAND_WITH_PHONE) as? HTMLImageElement)?.let {
        if (it.complete) Visibility.visible else Visibility.hidden
    } ?: Visibility.hidden
}
fun calculateHandWithPhoneContentBottom(scale: Double): Length {
    return when (scale) {
        TourAnimationConstants.mHandWithPhoneScale -> {
            calculateHandWithPhoneBottom()
                .plus((document.getElementById(Identifiers.TOUR_STAGE_1_HAND_WITH_PHONE)?.clientHeight?.div(100.0)?.times(33.8) ?: 0.0).px)
        }
        else -> {
            calculateHandWithPhoneBottom()
                .plus((document.getElementById(Identifiers.TOUR_STAGE_1_HAND_WITH_PHONE)?.clientHeight?.div(100.0)?.times(37.8) ?: 0.0).px)
        }
    }
}

fun calculateHandWithPhoneLeft(scale: Double): Length {
    return when (scale) {
        TourAnimationConstants.mHandWithPhoneScale -> 55.pct
        else -> 54.5.pct
    }
}

fun calculateCamIndicatorBottom(scale: Double): Length {
    return when (scale) {
        TourAnimationConstants.mHandWithPhoneScale -> {
            (document.getElementById(Identifiers.TOUR_BOTTOM_CONTENT)?.clientHeight ?: 0)
                .plus(document.getElementById(Identifiers.TOUR_STAGE_1_HAND_WITH_PHONE)?.clientHeight?.div(12) ?: 0).px
        }
        else -> {
            (document.getElementById(Identifiers.TOUR_BOTTOM_CONTENT)?.clientHeight ?: 0)
                .plus(document.getElementById(Identifiers.TOUR_STAGE_1_HAND_WITH_PHONE)?.clientHeight?.div(8) ?: 0).px
        }
    }
}

fun calculateHandWithPhoneContentHeightAsDouble(scale: Double): Double {
    return when (scale) {
        TourAnimationConstants.mHandWithPhoneScale -> {
            document.getElementById(Identifiers.TOUR_STAGE_1_HAND_WITH_PHONE)?.clientHeight?.div(1.26) ?: 0.0
        }
        else -> {
            document.getElementById(Identifiers.TOUR_STAGE_1_HAND_WITH_PHONE)?.clientHeight?.div(1.64) ?: 0.0
        }
    }
}

fun calculateFlashWidthAsDouble(): Double {
    return document.getElementById(Identifiers.TOUR_STAGE_1_HAND_WITH_PHONE)?.clientWidth?.div(2.05) ?: 0.0
}

fun calculateCamIndicatorSize(scale: Double): Length {
    return calculateHandWithPhoneContentHeightAsDouble(scale)
        .div(100.0).times(7.5).px
}

fun calculateHandWithPhoneContentHeight(scale: Double): Length {
    return calculateHandWithPhoneContentHeightAsDouble(scale).px
}

fun calculateHandWithPhoneContentWidth(): Length {
    return calculateFlashWidthAsDouble().px
}

fun calculateHandWithPhoneContentClipPath(scale: Double): String {
    return when (scale) {
        TourAnimationConstants.mHandWithPhoneScale -> "inset(0% 0% 0% 0% round ${if (window.isMobile()) 25 else 35}px)"
        else -> "inset(0% 0% 0% 0% round ${if (window.isMobile()) 15 else 20}px)"
    }
}

fun calculateColourItemHeightPosition(scale: Double, fraction: Double): Length {
    return calculateHandWithPhoneContentBottom(scale)
        .plus((calculateHandWithPhoneContentHeightAsDouble(scale).times(fraction)).px)
}

fun calculateColourItemSmallSquareHeightPosition(): Length {
    return (document.getElementById(Identifiers.TOUR_BOTTOM_CONTENT)?.clientHeight ?: 0).px
        .plus(5.pct)
}

fun updateDOM(transition: Transition<TourAnimationState>) {
    
    document.getElementById(Identifiers.TOUR_BOTTOM_CONTENT)?.let {
        val handWithPhoneSvgElements = document.getElementsByClassName(ECssClasses.TOUR_STAGE_1_HAND_WITH_PHONE.mClassName).asList()
        val handWithPhoneFlashElement = document.getElementById(Identifiers.TOUR_STAGE_1_HAND_WITH_PHONE_FLASH)
        val camIndicatorUnpressedElement = document.getElementById(Identifiers.TOUR_STAGE_1_CAM_INDICATOR_UNPRESSED)
        val camIndicatorPressedElement = document.getElementById(Identifiers.TOUR_STAGE_1_CAM_INDICATOR_PRESSED)

        handWithPhoneSvgElements.forEach { element ->
            (element as? HTMLImageElement)?.let {
                it.style.bottom = calculateHandWithPhoneBottom().toString()
                it.style.visibility = calculateHandWithPhoneVisibility().toString()
                it.style.left = calculateHandWithPhoneLeft(transition.mData.mScale).toString()
            }
        }
        (handWithPhoneFlashElement as? HTMLDivElement)?.let {
            it.style.bottom = calculateHandWithPhoneContentBottom(transition.mData.mScale).toString()
            it.style.height = calculateHandWithPhoneContentHeight(transition.mData.mScale).toString()
            it.style.width = calculateHandWithPhoneContentWidth().toString()
            it.style.setProperty("clip-path", calculateHandWithPhoneContentClipPath(transition.mData.mScale))
            it.style.visibility = calculateHandWithPhoneContentVisibility().toString()
        }
        (camIndicatorUnpressedElement as? HTMLImageElement)?.let {
            it.style.bottom = calculateCamIndicatorBottom(transition.mData.mScale).toString()
            it.style.height = calculateCamIndicatorSize(transition.mData.mScale).toString()
            it.style.width = calculateCamIndicatorSize(transition.mData.mScale).toString()
        }
        (camIndicatorPressedElement as? HTMLImageElement)?.let {
            it.style.bottom = calculateCamIndicatorBottom(transition.mData.mScale).toString()
            it.style.height = calculateCamIndicatorSize(transition.mData.mScale).toString()
            it.style.width = calculateCamIndicatorSize(transition.mData.mScale).toString()
        }
    }
}

fun visibilityCamIndicator(visibility: Visibility) {
    (document.getElementById(Identifiers.TOUR_STAGE_1_CAM_INDICATOR_UNPRESSED) as? HTMLImageElement)?.let {
        it.style.visibility = visibility.toString()
    }
}
