package core.util

import core.extensions.toMinimizedString
import kotlin.math.ceil

class QuantityCalculator {

    // ----------------------------------------------------------------------------
    // Public properties
    // ----------------------------------------------------------------------------

    // ----------------------------------------------------------------------------
    // Protected properties
    // ----------------------------------------------------------------------------

    // ----------------------------------------------------------------------------
    // Private properties
    // ----------------------------------------------------------------------------

    companion object {
        const val AlpinaFeineFarben = "Alpina Feine Farben"
        const val AlpinaFarbRezepte = "Alpina Farbrezepte"
        const val AlpinaFarbenfreunde = "Alpina Farbenfreunde"
        const val AlpinaColormix = "Alpina Color"
        const val AlpinaEdition = "Alpina Edition"
        const val PantonePoweredByAlpina = "PANTONE® POWERED by Alpina"
        const val AlpinaPureFarben = "Alpina Pure Farben"
    }

    // ----------------------------------------------------------------------------
    // Internal properties
    // ----------------------------------------------------------------------------

    // ----------------------------------------------------------------------------
    // Init
    // ----------------------------------------------------------------------------
    init {

    }

    // ----------------------------------------------------------------------------
    // Constructor
    // ----------------------------------------------------------------------------

    // ----------------------------------------------------------------------------
    // System/Overridden methods
    // ----------------------------------------------------------------------------

    // ----------------------------------------------------------------------------
    // Public methods
    // ----------------------------------------------------------------------------

    fun calculateQuantity(squareMeter: String, collectionName: String): Double? {
        return squareMeter.replace(",", ".").toDoubleOrNull()?.let {
            ceil(it / squareMeterPerLiter(collectionName))
        }
    }

    // ----------------------------------------------------------------------------
    // Protected methods
    // ----------------------------------------------------------------------------

    // ----------------------------------------------------------------------------
    // Private methods
    // ----------------------------------------------------------------------------

    private fun squareMeterPerLiter(collectionName: String): Double {
        return when (collectionName.toMinimizedString()) {
            AlpinaFeineFarben.toMinimizedString() -> 12.0
            AlpinaFarbRezepte.toMinimizedString() -> 8.0
            AlpinaFarbenfreunde.toMinimizedString() -> 8.0
            AlpinaColormix.toMinimizedString() -> 6.0
            AlpinaEdition.toMinimizedString() -> 9.0
            PantonePoweredByAlpina.toMinimizedString() -> 9.0
            AlpinaPureFarben.toMinimizedString() -> 10.0
            else -> 12.0
        }
    }

    // ----------------------------------------------------------------------------
    // Interfaces/Listener
    // ----------------------------------------------------------------------------

}