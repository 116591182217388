package common

enum class ETourIndicators(val mImageSrc: String) {
    // ----------------------------------------------------------------------------
    // Enumeration List
    // ----------------------------------------------------------------------------

    INDICATOR_1("/static/images/tour/card/card_indicator_1.svg"),
    INDICATOR_2("/static/images/tour/card/card_indicator_2.svg"),
    INDICATOR_3("/static/images/tour/card/card_indicator_3.svg"),
    INDICATOR_4("/static/images/tour/card/card_indicator_4.svg")

    // ----------------------------------------------------------------------------
    // Public methods
    // ----------------------------------------------------------------------------

    // ----------------------------------------------------------------------------
    // Protected methods
    // ----------------------------------------------------------------------------

    // ----------------------------------------------------------------------------
    // Private methods
    // ----------------------------------------------------------------------------
}