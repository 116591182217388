package components.alert

import core.enums.ELocalizationKeys
import core.ionic.AlertButton
import core.localization.Localization
import kotlin.js.Date
import kotlin.time.Duration.Companion.milliseconds

data class AlertItem(
    val mMessage: String,
    var mButtons: Array<AlertButton>,
    var mIsOpen: Boolean = true,
    val mKey: String = Date.now().milliseconds.toString()
) {

    companion object {
        fun confirmDeleteAlertItem(message: String, onConfirm: () -> Unit): AlertItem {
            return AlertItem(
                message,
                arrayOf(
                    object : AlertButton {
                        override var text: String = Localization.getLocalizationKey(ELocalizationKeys.CANCEL)
                        override var role: String? = "cancel"
                        override var cssClass: Array<String> = arrayOf()
                        override var handler: ((value: Any) -> Boolean)? = {
                            true
                        }

                    },
                    object : AlertButton {
                        override var text: String = Localization.getLocalizationKey(ELocalizationKeys.OK)
                        override var role: String? = "destructive"
                        override var cssClass: Array<String> = arrayOf()
                        override var handler: ((value: Any) -> Boolean)? = {
                            onConfirm()
                            true
                        }

                    }
                ))
        }

        fun okAlertItem(message: String, onOk: () -> Unit): AlertItem {
            return AlertItem(
                message,
                arrayOf(
                    object : AlertButton {
                        override var text: String = Localization.getLocalizationKey(ELocalizationKeys.OK)
                        override var role: String? = null
                        override var cssClass: Array<String> = arrayOf()
                        override var handler: ((value: Any) -> Boolean)? = {
                            onOk()
                            true
                        }

                    }
                ))
        }
    }

}
