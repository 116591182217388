package module.home

import csstype.Color
import csstype.Length

data class TourColourItem(
    var mColorValue: Color,
    var mAbsolutePositionLeft: Length,
    var mAbsolutePositionBottom: Length,
    var mColorSelected: Boolean = false
)
