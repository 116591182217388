package common.animation

import common.ETourColourItemState

data class TourAnimationState(
    val mFlashVisibility: Boolean = false,
    val mScale: Double = 1.0,
    val mColourItemState: ETourColourItemState = ETourColourItemState.INVISIBLE,
    val mIsMyColoursBackgroundVisible: Boolean = false,
    val mIsWholeBackgroundBlurred: Boolean = false,
    val mIsColourItemBuyOptionScaled: Boolean = false,
)
