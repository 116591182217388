package core.piwik

object Piwik {

    private const val CONTAINER_ID = "9e5379b3-d693-4d8e-b3fd-58083ca581fa"
    private const val CONTAINER_URL = "https://daw.piwik.pro"

    const val CATEGORY_RESTART = "restart"
    const val CATEGORY_COLOR_PICKER = "colorPicker"
    const val CATEGORY_WALLCOLORER = "wallcolorer"
    const val CATEGORY_COLORWALL = "colorwall"
    const val CATEGORY_LIVE_VIEW = "liveView"
    const val CATEGORY_COLORTONE_LIST = "colortoneList"
    const val CATEGORY_WISH_LIST = "wishlist"
    const val CATEGORY_PRODUCT_LIST = "productlist"
    const val CATEGORY_SEARCH = "search"
    const val CATEGORY_COMMERCE_CONNECTOR = "commerceConnector"

    const val NAME_RESTART_BURGER_MENU = "restartBurgerMenu"
    const val NAME_COLOR_PICKER_UPLOAD_IMAGE = "colorPickerUploadImage"
    const val NAME_COLORTONE_LIST_DELETE_COLOR = "colortoneListDeleteColor"
    const val NAME_WALLCOLORER_USE_SCENE = "wallcolorerUseScene"
    const val NAME_WALLCOLORER_SAVE_SCENE = "wallcolorerSaveScene"
    const val NAME_WALLCOLORER_SHARE_SCENE = "wallcolorerShareScene"
    const val NAME_WALLCOLORER_SAVE_IMAGE = "wallcolorerSaveImage"
    const val NAME_WALLCOLORER_SHARE_IMAGE = "wallcolorerShareImage"
    const val NAME_WALLCOLORER_USE_COLOR = "wallcolorerUseColor"
    const val NAME_LIVE_VIEW_OPEN = "liveViewOpen"
    const val NAME_COLORWALL_OPEN = "colorwallOpen"
    const val NAME_WISHLIST_COLOR_ADD = "wishlistColorAdd"
    const val NAME_WISHLIST_DOWNLOAD = "wishlistDownload"
    const val NAME_WISHLIST_SHARE = "wishlistShare"
    const val NAME_PRODUCTLIST_COLOR_ADD = "productlistColorAdd"
    const val NAME_PRODUCTLIST_DOWNLOAD = "productlistDownload"
    const val NAME_PRODUCTLIST_SHARE = "productlistShare"
    const val NAME_PRODUCTLIST_USE_AMOUNT_CALCULATOR = "productlistUseAmountCalculator"
    const val NAME_SEARCH_OPEN_RETAILER = "searchOpenRetailer"
    const val NAME_CC_OPEN = "commerceConnectorOpen"
    const val NAME_CC_OPEN_EMPTY_EAN = "commerceConnectorClickedEmptyEAN"

    const val TARGET_COMMERCE = "commerce"
    const val TARGET_COLOR = "color"
    const val TARGET_SCENE = "scene"

    fun init() {
        PiwikPro.initialize(CONTAINER_ID, CONTAINER_URL)
    }

    fun trackCustomEvent(category: String, action: String, name: String? = null, value: Number? = 0.0) {
        CustomEvent.trackEvent(category, action, name, value)
    }

    fun trackContentInteraction(contentInteraction: String, contentName: String, contentPiece: String, contentTarget: String) {
        ContentTracking.trackContentInteraction(contentInteraction, contentName, contentPiece, contentTarget)
    }

}