import csstype.Display
import io.ktor.client.call.*
import io.ktor.client.statement.*
import io.ktor.http.*
import kotlinx.browser.document
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.withContext
import org.w3c.dom.HTMLAnchorElement
import org.w3c.dom.url.URL
import org.w3c.files.Blob
import org.w3c.files.BlobPropertyBag

suspend fun HttpResponse.download(fileName: String) {
    withContext(Dispatchers.Main) {
        val a = document.createElement("a") as HTMLAnchorElement
        a.style.display = Display.none.toString()
        document.body?.appendChild(a)
        val image: ByteArray = this@download.readBytes()
        a.href = URL.createObjectURL(
            Blob(arrayOf(image))
        )

        a.setAttribute("download", fileName)
        a.click()
        URL.revokeObjectURL(a.href)
        document.body?.removeChild(a)
    }
}