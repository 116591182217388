package screens.static

import common.EConstants
import common.ECssClasses
import components.Toolbar
import components.burgerMenu.BurgerMenu
import core.enums.ELocalizationKeys
import core.ionic.*
import core.localization.Localization
import csstype.FlexGrow
import csstype.px
import react.FC
import react.Props
import react.css.css
import react.dom.html.ReactHTML.span
import setUnsafeInnerHTML

external interface ShowIntegrityScreenProps: Props {}

val ShowIntegrityScreen = FC<ShowIntegrityScreenProps> {
    IonPage {
        //id = EConstants.BURGER_MENU_CONTENT.mId

        IonHeader {
            Toolbar{
                mIsBackNavigation = true
            }
        }
        IonContent {
            IonGrid {
                css(ECssClasses.VERTICAL_GRID.mClassName, ECssClasses.NON_SCROLLABLE_GRID.mClassName) {}
                IonRow {
                    css(ECssClasses.FULL_WIDTH_ROW.mClassName) {
                        flexGrow = FlexGrow(1.0)
                    }
                    IonCol {
                        css(ECssClasses.FULL_WIDTH_COL.mClassName) {
                            marginTop = 12.px
                        }
                        span {setUnsafeInnerHTML(Localization.getLocalizationKey(ELocalizationKeys.INTEGRITY_CONTENT.name))}
                    }
                }
            }
        }
    }
    BurgerMenu()
}