package screens.colourFinder

import common.EConstants
import common.ECssClasses
import common.EEnvironmentVariables
import components.AlpinaLogo
import components.ButtonContent
import components.SliderRow
import components.Spacer.Spacer
import components.Toolbar
import components.alert.Alert
import components.alert.AlertItem
import components.burgerMenu.BurgerMenu
import components.loadingSpinner.LoadingSpinner
import core.AppContext
import core.enums.EImages
import core.enums.EJSBridgeActions
import core.enums.ELocalizationKeys
import core.enums.EPostMessageActions
import core.extensions.getValue
import core.extensions.isInNativeApp
import core.extensions.postMessageToNativeApp
import core.extensions.useCoroutineScope
import core.io.dto.JSBridgeActionDTO
import core.io.dto.MinifiedColourCollectionDTO
import core.ionic.*
import core.localStorage.LocalStorageDAO
import core.localization.Localization
import core.piwik.Piwik
import core.upload.UploadHelper
import core.util.exhaustive
import csstype.TextAlign
import csstype.pct
import csstype.px
import kotlinext.js.asJsObject
import kotlinx.browser.window
import kotlinx.coroutines.launch
import kotlinx.serialization.json.Json
import module.home.SliderObject
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.MessageEvent
import org.w3c.dom.events.Event
import react.*
import react.css.css
import react.dom.html.InputType
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.input
import react.router.Navigate
import screens.EScreens
import setUnsafeInnerHTML

external interface ColourFinderUploadScreenProps : Props {
    var mToken: String?
    var mNavigate: String?
}

val ColourFinderUploadScreen = FC<ColourFinderUploadScreenProps> { props ->

    var mTargetScreen: String? by useState(props.mNavigate)
    var mToken: String? by useState(props.mToken)
    val mFileInputRef = useRef<HTMLInputElement>(null)
    var mIsLoading by useState(false)
    val mScope = useCoroutineScope()
    val mContext by useContext(AppContext)
    val mSerializer = Json {
        ignoreUnknownKeys = true
        isLenient = true
    }
    var mAlertItem: AlertItem? by useState(null)

    useEffectOnce {
        val eventMethod: (Event) -> Unit = { event ->
            event as MessageEvent
            val target = event.data?.asJsObject().unsafeCast<String>()
            val dto = kotlin.runCatching { mSerializer.decodeFromString<MinifiedColourCollectionDTO>(target) }.getOrNull()
            when (dto?.mAction) {
                EPostMessageActions.SET_FIXED_LIST -> {
                    mContext.mLocalStorageReducer.component2().invoke(LocalStorageDAO.ELocalStorageAction.CLEAR_FIXED_LIST)
                    mContext.mLocalStorageReducer.component2().invoke(LocalStorageDAO.ELocalStorageAction.CLEAR_SUGGESTED_LIST)
                    dto.mData?.forEach {
                        mContext.mLocalStorageReducer.component2().invoke(
                            LocalStorageDAO.ELocalStorageAction.ADD_OR_DELETE_FROM_FIXEDLIST(
                                it
                            )
                        )
                    }
                }
                null -> {}
                else -> {}
            }.exhaustive
        }

        window.addEventListener(type = "message", eventMethod)
        cleanup {
            window.removeEventListener(type = "message", eventMethod)
        }
    }

    BurgerMenu()
    IonPage {
        id = EConstants.BURGER_MENU_CONTENT.mId
        IonHeader {
            Toolbar()
            AlpinaLogo()
        }

        IonContent {
            IonGrid {
                css(
                    classNames = arrayOf(
                        ECssClasses.VERTICAL_GRID.mClassName,
                        ECssClasses.NON_SCROLLABLE_GRID.mClassName
                    )
                ) {}
                SliderRow() {
                    mObjectList = listOf(SliderObject("", EImages.HEADER_IMAGE_S02_NEW.mRelativePath, 0))
                    mSliderIterator = 0
                }
                Spacer()
                IonRow {
                    IonCol {
                        size = "12"
                        css(ECssClasses.TITLE_FONT.mClassName) {
                            textAlign = TextAlign.center
                            marginBottom = 24.px
                        }
                        ReactHTML.span {
                            +Localization.getLocalizationKey(ELocalizationKeys.S02_TITLE_1.name)
                        }
                    }
                }
                IonRow {
                    IonCol {
                        size = "12"
                        css(ECssClasses.BODY_FONT.mClassName) {
                            textAlign = TextAlign.center
                        }
                        ReactHTML.span {
                            setUnsafeInnerHTML(Localization.getLocalizationKey(ELocalizationKeys.S02_TEXT_1.name))
                        }
                    }
                }
                Spacer()
                IonRow {
                    IonCol {
                        size = "12"
                        sizeSm = "9"
                        sizeMd = "8"
                        offset = "0"
                        offsetSm = "1.5"
                        offsetMd = "2"
                        IonButton {
                            css(ECssClasses.PRIMARY_BUTTON.mClassName) {
                                width = 100.pct
                            }
                            onClick = {
                                mFileInputRef.current?.click()
                            }
                            ButtonContent {
                                mLeftImage = EImages.CAMERA_ICON
                                mContent =
                                    Localization.getLocalizationKey(ELocalizationKeys.S02_BUTTON_CONTENT_TAKE_PICTURE.name)
                            }
                        }
                    }
                    input {
                        type = InputType.file
                        name = "selectFile"
                        ref = mFileInputRef
                        hidden = true
                        accept = UploadHelper.getAcceptedFileTypes()
                        onChange = {
                            mScope.launch {
                                mIsLoading = true
                                mToken = UploadHelper.handleFileInputChange(it.target.files?.item(0), 0)
                                Piwik.trackCustomEvent(
                                    Piwik.CATEGORY_COLOR_PICKER,
                                    Piwik.NAME_COLOR_PICKER_UPLOAD_IMAGE,
                                    Piwik.NAME_COLOR_PICKER_UPLOAD_IMAGE
                                )
                                if (mToken == null) mIsLoading = false
                            }
                        }
                    }
                }

                IonRow {
                    IonCol {
                        size = "12"
                        sizeSm = "9"
                        sizeMd = "8"
                        offset = "0"
                        offsetSm = "1.5"
                        offsetMd = "2"
                        IonButton {
                            css(ECssClasses.PRIMARY_BUTTON.mClassName) {
                                width = 100.pct
                            }
                            onClick = {
                                Piwik.trackCustomEvent(
                                    Piwik.CATEGORY_COLORWALL,
                                    Piwik.NAME_COLORWALL_OPEN,
                                    Piwik.NAME_COLORWALL_OPEN
                                )
                                if(window.isInNativeApp()){
                                    val jsBridgeAction = JSBridgeActionDTO<Unit>(
                                        EJSBridgeActions.SHOW_COLOURWALL
                                    )
                                    window.postMessageToNativeApp(jsBridgeAction)
                                }else{
                                    window.open("https://${EEnvironmentVariables.DEEPLINK_SUBDOMAIN.getValue()}/colourwall")
                                }
                            }
                            ButtonContent {
                                mLeftImage = EImages.COLOURWALL_ICON
                                if(window.isInNativeApp()) {
                                    mContent =
                                        Localization.getLocalizationKey(ELocalizationKeys.S02_SHOW_COLOURWALL_NATIVE_APP_BUTTON_TITLE.name)
                                }else{
                                    mContent =
                                        Localization.getLocalizationKey(ELocalizationKeys.S02_SHOW_COLOURWALL_BROWSER_BUTTON_TITLE.name)
                                }
                            }
                        }
                    }
                }

                if (mIsLoading) {
                    LoadingSpinner()
                }

                mToken?.let {
                    Navigate {
                        to = EScreens.COLOUR_FINDER_MODULE_SCREEN(it).getPath()
                    }
                }
                mTargetScreen?.let {
                    Navigate {
                        to = it
                    }
                }
            }
        }
    }
    mAlertItem?.let {
        Alert {
            this.mAlertItem = it
        }
    }
}