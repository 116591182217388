package core.io.dto

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import module.watchlist.ColourListObject

@Serializable
data class ColourDTO(

    @SerialName("unique")
    val mUnique: String,

    @SerialName("idx")
    val mIndex: Int,

    @SerialName("highlight")
    var mHighlight: Boolean,

    @SerialName("fixed")
    var mFixed: Boolean,

    @SerialName("name")
    val mName: String,

    @SerialName("artNr")
    val mArtNr: String,

    @SerialName("colourCode")
    val mColourCode: String,

    @SerialName("colourCollection")
    val mColourCollection: String,

    @SerialName("additionalInfo1")
    val mAdditionalInfo1: String,

    @SerialName("additionalInfo2")
    val mAdditionalInfo2: String,

    @SerialName("eanCode")
    val mEanCode: String

    )

fun ColourDTO.toColourListObject(): ColourListObject {
    return ColourListObject(
        mUnique,
        mName,
        mArtNr,
        mColourCode,
        null,
        true,
        mColourCollection,
        mAdditionalInfo1,
        mAdditionalInfo2,
        mEanCode
    )
}