package screens.static

import common.EConstants
import common.ECssClasses
import components.Spacer.Spacer
import components.Toolbar
import components.burgerMenu.BurgerMenu
import core.AppContext
import core.enums.EJSBridgeActions
import core.enums.ELocalizationKeys
import core.extensions.isInNativeApp
import core.extensions.postMessageToNativeApp
import core.io.dto.JSBridgeActionDTO
import core.ionic.*
import core.localStorage.LocalStorageDAO
import core.localization.Localization
import csstype.AlignItems
import csstype.px
import kotlinx.browser.window
import react.*
import react.css.css
import react.dom.html.ReactHTML.span

external interface UsageAnalysisScreenProps: Props {}

val UsageAnalysisScreen = FC<UsageAnalysisScreenProps> {

    val mContext by useContext(AppContext)

    val (mLocalStorageState, mLocalStorageDispatch) = mContext.mLocalStorageReducer

    val tracking = useMemo(mContext) {
        mContext.mLocalStorageReducer.component1().mSettingsObject?.mPiwikTrackingNativeActive
    }

    useEffect(tracking){
        if(window.isInNativeApp()){
            if(tracking == true){
                val jsBridgeActionDTO = JSBridgeActionDTO(
                    EJSBridgeActions.ACTIVATE_TRACKING,
                    0
                )
                window.postMessageToNativeApp(jsBridgeActionDTO)
            }else{
                val jsBridgeActionDTO = JSBridgeActionDTO(
                    EJSBridgeActions.DEACTIVATE_TRACKING,
                    0
                )
                window.postMessageToNativeApp(jsBridgeActionDTO)
            }
        }
    }

    IonPage {
        id = EConstants.BURGER_MENU_CONTENT.mId

        IonHeader {
            Toolbar {
                mIsBackNavigation = true
            }
        }
        IonContent {
            if(window.isInNativeApp()){
                IonCol {
                    IonRow {
                        css {
                            alignItems = AlignItems.center
                            marginLeft = 16.px
                            marginRight = 16.px
                        }
                        span {
                            css(ECssClasses.LIST_HEADER_FONT.mClassName) {
                                marginLeft = 0.px
                            }
                            +Localization.getLocalizationKey(ELocalizationKeys.MENU_TITLE_USAGE_ANALYSIS.name)
                        }
                        Spacer()
                        IonToggle {
                            checked = tracking ?: true
                            onClick = {
                                mLocalStorageDispatch.invoke(LocalStorageDAO.ELocalStorageAction.SET_PIWIK_NATIVE_TRACKING)
                            }
                        }
                    }
                    IonRow {
                        css {
                            marginLeft = 16.px
                            marginRight = 16.px
                        }
                        span {
                            css(ECssClasses.BODY_FONT.mClassName) {}
                            +Localization.getLocalizationKey(ELocalizationKeys.USAGE_ANALYSIS_TEXT.name)
                        }
                    }
                }
            }
        }
    }
    BurgerMenu()
}